import api from "@/endpoints/api"

export function addStroll(data) {
    return api.post('/api/anon/anon_map/', data)
}

export function getPublicMap(id) {
    return api.get(`/api/p-map/${id}/`)
}

export function getCategories() {
    return api.get('/api/categories/')
}