<template>
      <l-map
        ref="map"
        :useGlobalLeaflet="true"
        :key="mapKey"
        :zoom="zoom"
        :options={zoomControl:false}
        :center="center"
        @update:center="setCenter($event)"
        @update:zoom="setZoom($event)"
      >
        <l-tile-layer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          layer-type="base"
          name="OpenStreetMap"
        ></l-tile-layer>
        <l-marker-cluster-group>
          <l-marker
          id="checkpoint.id"
            v-for="(checkpoint, index) in locStore.checkpoints"
            :key="index"
            :title="checkpoint.name"
            :lat-lng="{lat: checkpoint.lat, lng: checkpoint.lng}"
            @click="this.locStore.setLocationOpen(checkpoint.id)"
          />
        </l-marker-cluster-group>
        <l-circle
          v-if="locStore.userMarker"
          ref="userMarker"
          :lat-lng="locStore.userMarker"
          :options="markerOptions"
        >
          <l-popup>{{ locStore.userMarker.lat }} {{ locStore.userMarker.lng }}</l-popup>
        </l-circle>
      </l-map>
</template>

<script>
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'vue-leaflet-markercluster/dist/style.css'
globalThis.L = L

import { LMap, LTileLayer, LMarker, LCircle, LPopup } from '@vue-leaflet/vue-leaflet'
import { locationStore } from '@/stores/location'

import { LMarkerClusterGroup } from 'vue-leaflet-markercluster'


export default {
  name: 'Map',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    LPopup,
    LMarkerClusterGroup
  },
  setup() {
    const locStore = locationStore()
    return { locStore }
  },
  data () {
    return {
      // get this from api - constant map center
      markerOptions: {
        color: 'red',
        fillColor: '#f03',
        fillOpacity: 0.5,
        radius: 500
      },
      center: [45.0903, -64.3635],
      mapKey: 1,
      zoom: 15
    }
  },
  watch: {
    'locStore.category': function() {
      // this forces the map to re-render when we're changing filters so that the points load properly
      this.mapKey = this.mapKey + 1
    },
    'locStore.subcategory': function() {
      // this forces the map to re-render when we're changing filters so that the points load properly
      this.mapKey = this.mapKey + 1
    },
    'locStore.userMarker': {
      // Cange center to users location when userMarker changes
      handler() {
        this.center = [this.locStore.userMarker.lat, this.locStore.userMarker.lng]
      },
      deep: true
    },
    'locStore.checkpoints': {
      // force the map to re-render when checkpoints get changed so the points load properly
      handler() {
        this.mapKey = this.mapKey + 1
      },
      deep: true
    }
  },
  methods: {
    // resets the center of the map to the users location
    resetUserCenter (location) {
      this.$refs.map.leafletObject.flyTo(location)
    },
    setCenter (center) {
      // set updated center if it differs from current center
      if (this.center[0] !== center.lat || this.center[1] !== center.lng) {
        this.center = [center.lat, center.lng]
      }
    },
    setZoom (zoom) {
      // set updated zoom if it differs from current center
      if (this.zoom !== zoom) {
        this.zoom = zoom
      }
    },
    zoomIn () {
      this.zoom += 1
    },
    zoomOut () {
      this.zoom -= 1
    }
  }
}
</script>
