<template>
  <div class="col m-0 p-0 container feet-background" v-if="location">
    <router-link :to="{ name: 'map' }">
      <div class="text-end poi-wrapper">
        <button class="btn btn-dark my-3">Back to Map</button>
      </div>
    </router-link>
    <img class="row poi-wrapper rounded shadow mb-3" :src="location.image" />
    <div class="row poi-wrapper rounded shadow p-3">
      <div class="title">{{ location.name }}</div>
      <div class="description">{{ location.caption }}</div>
    </div>
  </div>
  <div v-else class="container">No Location Found.</div>
</template>

<script>
import { locationStore } from '@/stores/location'

export default {
  name: 'POIView',
  props: ['poiId'],
  setup() {
    const locStore = locationStore()
    return { locStore }
  },
  data () {
    return {
      location: null,
    }
  },
  watch:{
    $route (to, from){
      this.getLocation()
    }
  },
  methods: {
    getLocation() {
      this.location = this.locStore.checkpoints.find((cp) => cp.id == this.poiId);
    }
  },
  mounted() {
    this.getLocation()
  }
}
</script>

<style scoped>
  .container {
    margin-top: 64px !important;
  }
  .poi-wrapper {
    width: 80%;
    margin: auto;
  }
  .title {
    font-size: 30px;
    font-weight: 900;
  }
  .description {
    font-weight: 200;
  }
</style>