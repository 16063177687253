import { defineStore } from 'pinia'
import { getPublicMap, getCategories } from '@/services/strollService'


export const locationStore = defineStore('location', {
  state: () => {
    return {
      userMarker: null,
      menuOpen: false,
      filtersOpen: false,
      locationOpen: false,
      // checkpoints from api
      checkpoints: [],
      categories: [],
      sub_categories: null,
      category: null,
      subcategory: null,
      poi: null,
      map_id: null
    }
  },
  actions: {
    setMenuOpen () {
      this.menuOpen = !this.menuOpen
    },
    setFiltersOpen() {
      this.filtersOpen = !this.filtersOpen
    },
    async fetchCheckpoints (map_id) {
      this.map_id = map_id
      await getPublicMap(map_id).then((response) => {
        this.checkpoints = response.data.pois
      }, (error) => {
          console.log(error)
        }
      )
    },
    async fetchCategories () {
      await getCategories().then((response) => {
        this.categories = response.data
      }, (error) => {
          console.log(error)
        }
      )
    },
    setLocationOpen(poi_id) {
      if (poi_id) {
        console.log("POI", this.checkpoints.find((cp) => cp.id == poi_id))
        this.poi = this.checkpoints.find((cp) => cp.id == poi_id)
        this.locationOpen = true
      } else {
        this.locationOpen = false
      }
    },
    setUserMarker (data) {
      this.userMarker = data
      this.setDistance()
    },
    async setCategory (id) {
      this.category = this.categories.find((cat) => cat.id == id)
      this.sub_categories = this.category.sub_categories
      await this.fetchCheckpoints(this.map_id)
      // filter checkpoints by category
      this.checkpoints = this.checkpoints.filter((p) => p.sub_categories.some((sc) => sc.owner.id == id))
    },
    async setSubcategory (id) {
      this.subcategory = this.sub_categories.find((cat) => cat.id == id)
      await this.fetchCheckpoints(this.map_id)
      // filter checkpoints by subcategory
      this.checkpoints = this.checkpoints.filter((p) => p.sub_categories.some((sc) => sc.id == id))
    },
    async clearCategories () {
      this.sub_categories = null,
      this.category = null,
      this.subcategory = null
      await this.fetchCheckpoints(this.map_id)
    },
    setDistance () {
      this.checkpoints.forEach(checkpoint => {
        if (this.userMarker) {
          checkpoint.distance = Math.round(this.getDistance(checkpoint.lat, checkpoint.lng))
        }
      })
    },
    // function to calculate the distance between 2 coords
    getDistance (lat1, lon1) {
      const lat2 = this.userMarker.lat
      const lon2 = this.userMarker.lng
      const R = 6371 // Radius of the earth in km
      const dLat = this.deg2rad(lat2 - lat1)
      const dLon = this.deg2rad(lon2 - lon1)
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      const d = R * c // Distance in km
      return d
    },
    deg2rad (deg) {
      return deg * (Math.PI / 180)
    }
  }
})
