<template>
  <div class="col m-0 p-0">
    <div class="col z-1 position-absolute" style="margin-top: 64px;">
      <div class="fixed-bottom d-flex justify-content-between">
        <div class="col text-start ms-4 mb-4">
          <div>
            <button
              title="Zoom In"
              class="zoom-btn p-0 btn btn-light rounded-0 shadow"
              :class="{'push-over': locStore.menuOpen}"
              @click="$refs.map.zoomIn()"
            >+</button>
          </div>
          <div>
            <button
              title="Zoom out"
              class="zoom-btn p-0 btn btn-light rounded-0 shadow"
              :class="{'push-over': locStore.menuOpen}"
              @click="$refs.map.zoomOut()"
            >-</button>
          </div>
        </div>
        <div>
          <button
            v-if="cookies.get('map_id')"
            title="Share"
            class="locate-btn btn btn-light btn-lg border-0 rounded-circle shadow"
            @click="copyMapLink"
          >
            <font-awesome-icon icon="fa-solid fa-share" />
          </button>
          <router-link to="/">
            <button title="Builder" class="locate-btn btn btn-light btn-lg border-0 rounded-circle m-4 shadow">
              <font-awesome-icon icon="fa-solid fa-plus" />
            </button>
          </router-link>
        </div>
      </div>
      <!-- Filters -->
      <Transition name="collapse">
        <div v-if="this.locStore.filtersOpen" class="filters fixed-top d-flex py-2" :class="{'push-over': locStore.menuOpen}">
          <div class="col-4 d-flex align-items-center">
            <select v-model="category" class="form-select form-select-lg ms-3 rounded-pill" aria-label=".form-select-lg example">
              <option disabled value="">category</option>
              <option
              v-for="(cat, key) in locStore.categories" 
              v-bind:key="key"
              :value="cat.id"
              >
                {{ cat.name }}
              </option>
            </select>
          </div>
          <div class="col-4 d-flex align-items-center">
            <select :disabled="!locStore.sub_categories" v-model="subcategory" class="form-select form-select-lg ms-3 rounded-pill" aria-label=".form-select-lg example">
              <option disabled value="">sub-category</option>
              <option
              v-for="(cat, key) in locStore.sub_categories"
              v-bind:key="key"
              :value="cat.id"
              >
                {{ cat.name }}
              </option>
            </select>
          </div>
          <div class="col-4 d-flex align-items-center justify-content-end">
            <button class="btn btn-light me-3 rounded-pill" @click="locStore.setFiltersOpen">
              Hide
              <font-awesome-icon icon="fa-regular fa-eye-slash" />
            </button>
          </div>
        </div>
      </Transition>
    </div>

    <Transition name="fade">
      <div id="overlay" v-if="this.locStore.locationOpen" style="position: fixed; height: 100% !important; width: 100% !important; padding-top: 100px;" class="popup-overlay z-1 text-dark" :class="{'push-left': locStore.menuOpen}" @click="this.locStore.setLocationOpen()">
        <div>
          <div class="popup rounded shadow text-dark p-3 m-3">
            <div class="col m-0 p-0">
              <img class="row poi-wrapper rounded shadow mb-3" :src="this.locStore.poi.file_url" />
              <div class="row poi-details rounded shadow p-3">
                <div class="title">{{ this.locStore.poi.name }}</div>
                <div class="description">{{ this.locStore.poi.caption }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Map ref="map" class="z-0" />
  </div>
</template>

<script>
import Map from '@/components/Map.vue'
import { locationStore } from '@/stores/location'
import { useCookies } from "vue3-cookies"
import { useToast } from "vue-toastification"

export default {
  name: 'HomeView',
  components: {
    Map
  },
  props: {
    mapID: {
      type: String,
      default: ""
    },
  },
  beforeRouteEnter(to, from, next) {
    // Called when the component is initially created
    next(vm => {
      vm.initialLoad(vm)
    })
  },
  beforeRouteUpdate(to, from, next) {
    // Called when the route changes but the component is reused
    this.initialLoad(this)
    next()
  },
  setup() {
    const toast = useToast()
    const { cookies } = useCookies()
    const locStore = locationStore()
    return { cookies, locStore, toast }
  },
  data () {
    return {
      show: false,
      userLocation: null,
      showPopup: false,
      category: "",
      subcategory: ""
    }
  },
  watch:{
    category (id){
        if (id !== "") this.locStore.setCategory(id)
    },
    subcategory (id){
        if (id !== "") this.locStore.setSubcategory(id)
    },
    'locStore.category': function(newVal) {
        if (!newVal) {
          this.category = ""
          this.subcategory = ""
        } else {
          this.subcategory = ""
        }
    }
  },
  methods: {
    initialLoad (vm) {
      vm.locStore.fetchCategories()
      if (vm.mapID) {
        vm.locStore.fetchCheckpoints(vm.mapID)
      } else {
        const map_id = vm.cookies.get('map_id')
        if (map_id) {
          vm.locStore.fetchCheckpoints(map_id)
        }
      }
    },
    geolocate (reset = true) {
      // if user clicks the location button, check if they are allowing location services and if not have a pop up saying they need to enable that for it to work
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = [position.coords.latitude, position.coords.longitude]
        if (reset) this.$refs.map.resetUserCenter(pos)
        this.locStore.setUserMarker({
          lat: pos[0],
          lng: pos[1]
        })
      })
    },
    closePopup(e, overlay=true) {
      if (overlay && e.target.id === "overlay"){
            this.showPopup = false
      }
    },
    copyMapLink () {
      const map_id = this.cookies.get('map_id')
      const url = window.location.href
      navigator.clipboard.writeText(`${url}${map_id}`)
      this.toast.success("Copied share link copied to clipboard",{
        position: "bottom-right",
        timeout: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      })
    }
  },
  created () {
    this.geolocate(false)
  }
}
</script>

<style scoped>
.locate-btn {
  padding: 10px 16px;
}
.zoom-btn {
  font-weight: 1000;
  font-size: 25px;
  width: 40px;
  height: 40px;
  transition: margin .35s ease;
}
.push-over {
  margin-left: 260px;
}
.push-left {
  padding-right: 260px;
}
.popup-overlay {
  background-color: rgba(0, 0, 0, 0.6);
}
.popup {
  background-color: rgba(255, 255, 255, 0.4);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.collapse-enter-active, .collapse-leave-active {
  transition: all 0.5s ease;
}

.collapse-enter-from, .collapse-leave-to {
  opacity: 0;
}
.filters {
  height: 60px;
  margin-top: 64px;
  background-color: rgba(255, 255, 255, 0.7);
}
.poi-wrapper {
    width: 80%;
    margin: auto;
}
.poi-details {
    width: 80%;
    margin: auto;
    background-color: white;
}
.title {
  font-size: 26px;
  font-weight: 900;
}
.description {
  font-weight: 300;
}
</style>
