import Dexie from 'dexie';

export const db = new Dexie('strollopia');

const poisIndexes = [
  'image', 'caption', 'latitude', 'longitude', 'sub_categories' //'filters', 'icon', 'color', 'name'
]

db.version(1).stores({
  stroll: 'owner, name',
  pois: poisIndexes.join(', '),
})