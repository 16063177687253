<template>
  <div class="vh-100">
    <div class="nav-bar row align-items-center bg-white z-2 position-absolute w-100 m-0 p-0" style="height: 64px;">
      <div class="col text-start">
        <a v-if="!showBuilder" href="#" title="Menu" data-bs-target="#sidebar" data-bs-toggle="collapse" class="btn btn-lg btn-light border-0 rounded-circle" @click="locStore.setMenuOpen"><font-awesome-icon icon="fa-solid fa-bars" /></a>
        <router-link v-if="showBuilder" :to="{ name: 'map' }">
          <button title="Map" class="map-btn btn btn-lg btn-light border-0 rounded-circle">
            <font-awesome-icon icon="fa-solid fa-map" />
          </button>
        </router-link>
      </div>
      <div class="nav-title col" :class="{'text-end': showBuilder}">
        SPRING CLEAN UP
      </div>
      <div class="col text-end" v-if="!showBuilder">
        <button v-if="locStore.category || locStore.subcategory" title="Clear Filters" @click="this.locStore.clearCategories" class="btn btn-lg btn-light">
          Clear Filters
        </button>
        <button v-else title="Filters" @click="showFilters()" class="btn btn-lg btn-light border-0 rounded-circle" id="dropdownMenuLink" style="padding: 8px 14px;">
          <font-awesome-icon icon="fa-solid fa-filter" />
        </button>
      </div>
    </div>
    <div class="d-flex flex-column h-100">
      <div class="row flex-grow-1 m-0 p-0 bg-white">
        <div class="col-auto collapse collapse-horizontal overflow-hidden p-0" id="sidebar">
          <div class="col" style="width: 264px; padding-top: 80px; padding-right: 12px;">
            <div
              v-for="(checkpoint, key) in locStore.checkpoints"
              v-bind:key="key"
              class="stroll-menu-item menu-item row flex-nowrap py-2"
              style="cursor: pointer;"
              @click="this.locStore.setLocationOpen(checkpoint.id)"
            >
              <div class="col-3 my-auto ps-1 pe-0">
                <div class="menu-icon badge bg-dark rounded-circle">
                  <font-awesome-icon class="align-middle" icon="fa-solid fa-location-dot"/>
                </div>
              </div>
              <div class="col-9 px-0">
                <div class="text-start text-truncate fw-bold">{{ checkpoint.name }}</div>
                <div class="text-start">
                  <font-awesome-icon class="text-secondary" icon="fa-solid fa-location-crosshairs" />
                  <span class="fw-light ps-1">{{checkpoint.distance ? checkpoint.distance : '0'}}km</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { locationStore } from '@/stores/location'

export default {
  name: 'App',
  setup() {
    const locStore = locationStore()
    return { locStore }
  },
  data () {
    return {
      showBuilder: false
    }
  },
  mounted() {
    document.title = 'Spring Clean Up'
  },
  watch:{
    $route (to, from){
        if (to.name == "builder") {
          this.showBuilder = true
        } else {
          this.showBuilder = false
        }
    }
  },
  methods: {
    showFilters() {
      this.locStore.setFiltersOpen()
      if (this.locStore.locationOpen) this.locStore.setLocationOpen()
    }
  }
}
</script>

<style>
#app {
  text-align: center;
}
.nav-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 1;
}
.nav-bar {
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
}
.menu-icon {
  font-size: 20px !important;
  width: 40px;
  height: 40px;
}
.stroll-menu-item:hover {
  background-color:#a3b49f;
}
.dropdown-item:hover {
  background-color:#a3b49f;
}
.dropdown-item:active {
  color:black;
}
.map-btn {
  padding: 10px 16px !important;
}
</style>
