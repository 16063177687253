import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueMobileDetection from "vue-mobile-detection"
import { createPinia } from 'pinia'
import 'leaflet/dist/leaflet.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faBars, faLocationCrosshairs, faLocationDot, faCog, faWrench, faFilter, faMap, faCamera, faShare,
  faPlus
} from '@fortawesome/free-solid-svg-icons'

import './css/styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import { faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css";

library.add(faBars)
library.add(faLocationCrosshairs)
library.add(faLocationDot)
library.add(faCog)
library.add(faWrench)
library.add(faFilter)
library.add(faMap)
library.add(faEyeSlash)
library.add(faCamera)
library.add(faShare)
library.add(faPlus)

const pinia = createPinia()

createApp(App)
  .use(router)
  .use(VueGoogleMaps, {
    load: {
      key: "AIzaSyDbwXMGD3Bsa8FnZdeBBHxXUKmKZFaYhmE",
    },
  })
  .use(VueMobileDetection)
  .use(pinia)
  .use(Toast)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')