import api from "@/endpoints/api"
import axios from "axios"


// export function s3Upload(file) {
//     // Upload a file to S3 by first fetching a signed URL from backend,
//     // and then uploading the file directly to our S3 bucket
//     return api.get("/api/media/signed-url/", {params: {filename: file.name}}).then(({ data }) => {
//       // Upload directly to S3
//       const options = {
//         headers: {
//           "Content-Type": file.type,
//           "x-amz-acl": "public-read"
//         }
//       }
//       const publicUploadID = data.publicUploadID
  
//       // Use Signed URL to upload directly to S3
//       axios.put(data.uploadURL, file, options).then(res => {
//         api.put(`/api/media/public-upload/${publicUploadID}/process/`, {publicUploadID: publicUploadID}).then(res => {
//           console.log("Processing successful: ", res)
//         }).catch(err => {
//           console.log("Error processing file: ", err)
//         })
//       }).catch(err => {
//         console.log("Error uploading file: ", err)
//       })
//       return data
//     })
//   }

export async function s3Upload(file) {
    try {
        // Fetch a signed URL from backend
        const { data } = await api.get("/api/media/signed-url/", {params: {filename: file.name}})
        const options = {
            headers: {
                "Content-Type": file.type,
                "x-amz-acl": "public-read"
            }
        }
        const publicUploadID = data.publicUploadID

        // Use Signed URL to upload directly to S3
        await axios.put(data.uploadURL, file, options)
        try {
            await api.put(`/api/media/public-upload/${publicUploadID}/process/`, {publicUploadID: publicUploadID})
        } catch (err) {
            console.log("Error processing file: ", err)
        }

        // Return publicUploadID
        return publicUploadID;
    } catch (err) {
        console.log("Error uploading file: ", err)
    }
}