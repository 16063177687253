import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Builder from '../views/Builder.vue'
import PointOfInterest from '../views/PointOfInterest.vue'

const routes = [
  {
    path: '/map/',
    name: 'map',
    component: HomeView
  },
  {
    path: '/map/:mapID',
    name: 'mapDetail',
    component: HomeView,
    props: route => {
      return {
        mapID: route.params.mapID,
      }
    }
  },
  {
    path: '/',
    name: 'builder',
    component: Builder
  },
  {
    path: '/map/:poiId',
    name: 'PointOfInterest',
    component: PointOfInterest,
    props: route => {
      return {
        poiId: route.params.poiId,
      }
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
