import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export const getCsrfToken = () => {
  const token = document.querySelector("[name=csrfmiddlewaretoken]")
  if (token) {
    return token.value
  }

  return cookies.get("csrftoken")
}
