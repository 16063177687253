<template>
  <div class="p-3 p-md-5">
    <div v-if="setupStroll">
      <div class="h3 mt-5 mb-4 fw-bold">
        ADD AN ITEM:
      </div>
      <div class="mb-5">
        <input
          class="email form-control border-0 rounded-pill shadow"
          v-model="stroll.email"
          type="text"
          id="email"
          placeholder="Email (optional)"
        />
      </div>
      <div class="h5 fw-bold text-start">
        Select Location:
      </div>
      <div
        v-if="desktop"
        class="mt-2 mb-5 border rounded shadow"
      >
        <GMapMap
          :center="center"
          :zoom="mapZoom"
          map-style-id="roadmap"
          :options="mapOptions"
          style="width: 100%; height: 40vmin"
          ref="mapRef"
          @click="handleMapClick"
        >
          <GMapMarker
            :position="marker.position"
            :clickable="true"
            :draggable="true"
            @drag="handleMarkerDrag"
            @click="panToMarker"
          />
        </GMapMap>
      </div>
      <!-- Filters -->
      <div class="h5 fw-bold text-start">
        Select Categories:
      </div>
      <div class="d-flex mb-5">
        <div class="col-6 d-flex align-items-center">
          <select v-model="category" class="form-select form-select-lg rounded-pill" aria-label=".form-select-lg example">
            <option disabled value="">category</option>
            <option
            v-for="(cat, key) in locStore.categories" 
            v-bind:key="key"
            :value="cat.id"
            >
              {{ cat.name }}
            </option>
          </select>
        </div>
        <div class="col-6 d-flex align-items-center">
          <select
            :disabled="!category"
            v-model="subcategory"
            class="form-select form-select-lg ms-3 rounded-pill"
            aria-label=".form-select-lg example"
            required
          >
            <option disabled value="">sub-category</option>
            <option
            v-for="(cat, key) in sub_categories"
            v-bind:key="key"
            :value="cat.id"
            >
              {{ cat.name }}
            </option>
          </select>
        </div>
      </div>
      <!-- Image -->
      <div class="h5 fw-bold text-start">
        Add Image:
      </div>
      <div class="input-group custom-file-button mb-5">
        <input
          type="file"
          ref="fileInput"
          class=" pic-field form-control border-0 shadow"
          id="image"
          @change="convertImage"
          accept="image/jpeg"
        >
        <label class="pic-button input-group-text border-0 shadow" for="inputGroupFile">
          <font-awesome-icon icon="fa-solid fa-camera" style="font-size: 30px" />
        </label>
      </div>
      <!-- Details -->
      <div class="h5 fw-bold text-start">Details:</div>
      <!-- Name is temporary not used -->
      <!-- <div class="mb-3">
        <input
          class="description form-control border-0 shadow"
          v-model="poi.name"
          type="text"
          id="name"
          placeholder="Name"
        />
      </div> -->
      <div class="mb-3">
        <textarea
          class="description form-control border-0 shadow"
          v-model="poi.caption"
          type="text"
          id="caption"
          height="48"
          placeholder="Description"
        />
      </div>
      <button
        class="builder-btn btn border-0 rounded-pill"
        :disabled="submitDisabled"
        @click="addAnother()"
      >
        ADD ANOTHER</button>
      <button
        class="builder-btn btn border-0 rounded-pill"
        :disabled="submitDisabled"
        @click="submit()"
      >
        SUBMIT
      </button>

      <div class="pull-right mt-3">
        <!-- OLD BUTTONS -->
        <!-- <button
          class="btn btn-sm btn-dark rounded-0"
          @click="savePoiIDB"
          :disabled="poiError"
        >
          Save
        </button>
        <button
          class="me-2 btn btn-sm btn-dark rounded-0"
          @click="showComplete=false, setupPoi=true"
        >
          Add Another!
        </button>
        <button
          class="btn btn-sm btn-dark rounded-0"
          @click="saveStroll"
          :disabled="!online"
        >
          Save Stroll
        </button> -->
      </div>
    </div>
 
  </div>
</template>

<script>
import '../css/styles.css'
import { db } from '../db';
import { addStroll } from '../services/strollService'
import { s3Upload } from '../services/fileUpload'
import { useCookies } from "vue3-cookies"
import { locationStore } from '@/stores/location'
import imageCompression from 'browser-image-compression'
import { useToast } from "vue-toastification"

export default {
  name: "BuilderView",
  setup() {
    const { cookies } = useCookies()
    const locStore = locationStore()
    const toast = useToast()
    return { cookies, locStore, toast }
  },
  data() {
    return {
      marker: { position: { lat: 45.0854912, lng: -64.3497984 } },
      center: { lat: 45.0854912, lng: -64.3497984 },
      mapOptions: {
        // disableDefaultUI: true,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
      },
      mapZoom: 10,
      setupStroll: true,
      setupPoi: false,
      showComplete: false, 
      stroll: {},
      poi: {
        filters: [],
      },
      currentPoiNames: [],
      poiError: false,
      builder_key: null,
      owner_counter: null,
      online: true,
      desktop: false,
      imageFile: null,
      category: "",
      sub_categories: null,
      subcategory: "",
      map_id: null,
      pass_key: null,
      positionSelectedManually: false
    };
  },
  computed: {
    strollPasses() {
      return this.stroll.owner && this.stroll.name
    },
    submitDisabled() {
      return !this.poi.image
    }
  },
  watch: {
    setupPoi() {
      if (this.setupPoi) {
        this.geolocate()
      }
    },
    "poi.name": {
      immediate: true,
      handler(val) {
        // error checking for if poi name has been used before
        if (this.currentPoiNames.includes(val)) {
          this.poiError = true
        } else {
          this.poiError = false
        }
      }
    },
    category(id) {
      let cat = null
      if (this.category) {
        cat = this.locStore.categories.find((cat) => cat.id == id)
      }
      this.sub_categories = cat.sub_categories
    }
  },
  methods: {
    refresh() {
      this.card = {}
    },
    async saveStrollIDB() {
      // clear IDB before saving any info in case we're editing an unsaved stroll
      this.clearIDB(false)
      try {
        await db.stroll.add({
          owner: this.stroll.owner,
          name: this.stroll.name,
        });
        this.setupStroll = false
        this.setupPoi = true
      } catch (error) {
        console.log("there was an error adding the stroll to indexeddb")
      }
    },
    resetForm() {
      this.poi = {filters: []}
      this.currentPoiNames = []
      this.imageFile = null
      this.$refs.fileInput.value = null
      this.subcategory = ""
      this.positionSelectedManually = false
    },
    async addAnother() {
      await this.savePoiIDB()
      this.resetForm()
    },
    submit() {
      this.savePoiIDB(true)
    },
    async savePoiIDB(submit=false) {
      if (!this.positionSelectedManually){
        // get geolocation before saving poi
        await this.geolocate()
      }
      let publicUploadID = null
      if (this.imageFile){
        // upload image to s3
        // TODO: Postpone sending fole to S3 until stroll is saved when online
        publicUploadID = await s3Upload(this.imageFile)
      }


      try {
        await db.pois.add({
          latitude: this.marker.position.lat,
          longitude: this.marker.position.lng,
          image: publicUploadID ? publicUploadID : null,
          caption: this.poi.caption,
          sub_categories: this.subcategory ? [this.subcategory] : [],
          //name: this.poi.name,
          //image: this.poi.image,
          // TODO: sub_categories is a M2M field, so it should be an array of objects
          // filters: [...this.poi.filters],
          // icon: this.poi.icon ? this.poi.icon.name : "fa-solid fa-circle",
          // color: this.poi.color ? this.poi.color.name : "#476bd9",
        });
        this.setupPoi = false
        this.showComplete = true
        this.poi = {filters: []}
        // add list of names of existing pois for error checking
        await db.pois.toArray((data) => {
          console.log(data)
          data.forEach(poi => this.currentPoiNames.push(poi.name))
        })
        if (submit) {
          await this.saveStroll()
        }
      } catch (error) {
        console.log("there was an error adding the poi to indexeddb", error)
      }
    },
    async saveStroll() {
      let data = await this.getStrollJSON()
      addStroll(data).then(response => {
        this.cookies.set('pass_key', response.data.pass_key)
        this.cookies.set('map_id', response.data.map_id)
        this.cookies.set('id_field', response.data.id_field)
        this.clearIDB(true)
        this.resetForm()

        this.$router.push({ name: 'map' })
      }, error => {
        // ADD ERROR OUTPUT SOMEWHERE
        console.log(error)
      })
    },
    async getStrollJSON() {
      let savedStroll = {
        org_domain_name: 'api.strollopia.com',
        org_key: 'abcd',
        id_field: this.cookies.get('id_field'),
        name: "noname",  //this.stroll.owner,
        email: this.stroll.email ? this.stroll.email : "",
        map_id: this.map_id,
        pass_key: this.pass_key,
        map_json: {
          title: this.stroll.email ? this.stroll.email : "no title",
          description: "no description",
          pois: []
        }
      }
      await db.pois.toArray((data) => {
        savedStroll.map_json.pois = data
      })
      return JSON.stringify(savedStroll)
    },
    clearIDB(clearPois=true) {
      db.stroll.clear()
      // db.filters.clear()
      // db.icons.clear()
      // db.colors.clear()
      if (clearPois) {
        db.pois.clear()
      }
    },
    async loadIDB() {
      // if stroll exists in the indexeddb then populate stroll data
      await db.stroll.toArray((data) => {
        if (data.length > 0) {
          this.stroll = data[0]
        }
      })
      // list out existing poi names 
        await db.pois.toArray((data) => {
          data.forEach(poi => this.currentPoiNames.push(poi.name))
        })
    },
    async convertImage(e) {
      let img = e.target.files[0]
      // Check if the file is an image
      if (!img.type.startsWith('image/')) {
        // TODO: Display error in a more user-friendly way
        alert('File is not an image.')
        return
      }

      let poi = this.poi
      let reader = new FileReader();
      reader.onloadend = function() {
        poi.image = reader.result
      }
      reader.readAsDataURL(img);

      // Compress the image
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      try {
        const compressedFile = await imageCompression(img, options)
        // set optimized file as imageFile
        this.imageFile = compressedFile
      } catch (error) {
        console.error('Error compressing image:', error)
        // set original file as imageFile
        this.imageFile = img
      }
    },
    geolocate() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.marker.position.lat = position.coords.latitude;
            this.marker.position.lng = position.coords.longitude;
            if (this.desktop && this.setupPoi) {
              this.panToMarker()
            }
            resolve(position)
          },
          (error) => {
            this.toast.error(
              "There was an error accessing your geolocation. Please ensure you have granted the necessary permissions.",
              {
                position: "bottom-right",
                timeout: 10000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
              })
            reject(error)
          }
        )
      })
    },

    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },

    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      this.mapZoom = 14
    },

    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.positionSelectedManually = true
    },
    updateOnlineStatus() {
      navigator.onLine ? this.online = true : this.online = false
    }
  },
  mounted() {
    // get list of categories
    this.locStore.fetchCategories()

    if (this.cookies.get('pass_key')) {
      this.pass_key = this.cookies.get('pass_key')
    }
    if (this.cookies.get('map_id')) {
      this.map_id = this.cookies.get('map_id')
    }

    // detect if user is online/offline
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)

    //detect if the user is on phone or desktop/tablet
    this.$isMobile() ? this.desktop = false : this.desktop = true

    // get geolocation
    this.geolocate()

    // load existing idb data (will exist if they closed without saving after making pois)
    this.loadIDB()

  }
};
</script>

<style scoped>
.advanced {
  text-decoration: none !important;
}
.advanced:hover {
  text-decoration: underline !important;
}
::placeholder {
  opacity: .6;
  font-weight: 300;
}
.dropdown-menu {
    max-height: 280px;
    overflow-y: auto;
}
.color-dropdown-item:hover {
  opacity: .7;
}
.form-check-input:checked {
    background-color: #212529;
    border-color: #212529;
}
.form-check-input:focus {
    box-shadow: none;
}
input[type=file]::file-selector-button {
  background-color: #212529 !important;
  color: white !important;
  border: none !important;
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 2;
  width: 150px;
  color: white;
  font-size: 12px;
  background-color: #212529;
  font-weight: 400;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
  opacity: 0;
  transition:opacity 0.2s linear;
}

.tooltip-text::before {
  content: "";
  position: absolute;
  transform: rotate(45deg);
  background-color: #192733;
  padding: 5px;
  z-index: 1;
}

.hover-text:hover .tooltip-text {
  opacity: 1;
  visibility: visible;
}

#right {
  top: -33px;
  left: 120%;
}

#right::before {
  top: 35%;
  left: -2%;
}

.hover-text {
  position: relative;
  display: inline-block;
  text-align: center;
}

.custom-file-button input[type=file] {
  margin-left: -2px !important;
}

.custom-file-button input[type=file]::-webkit-file-upload-button {
  display: none;
}

.custom-file-button input[type=file]::file-selector-button {
  display: none;
}

.custom-file-button:hover label {
  color: white;
  cursor: pointer;
}
.pic-button {
  padding: 30px 40px 30px 30px;
  background-color: #869b7d;
  border-radius: 0px 24px 24px 0px;
}
.pic-field {
  padding: 30px;
  background-color: #a9b8a0;
  font-style: italic;
  font-size: 19px;
  font-weight: 300;
  border-radius: 24px 0px 0px 24px;
}
.description {
  background-color: #a9b8a0;
  border-radius: 24px;
}
.description::placeholder {
  font-style: italic;
  font-size: 19px !important;
  font-weight: 600 !important;
  color: black;
}
.email {
  background-color: #a9b8a0;
}
.email::placeholder {
  font-style: italic;
  font-size: 19px !important;
  font-weight: 600 !important;
  color: black;
}
.h5{
  font-weight: 800 !important;
}
.h3 {
  font-weight: 900 !important;
  font-size: 30px;
}
.builder-btn {
  width: 100%;
  padding: 12px;
  font-size: 20px;
  font-weight: 800 !important;
  color: white;
  background-color: black !important;
  margin-bottom: 10px;
}
.builder-btn:hover {
  background-color:#718867 !important;
  color: black;
}
</style>